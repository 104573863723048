import type { ContactEmail, ContactPhoneNumber } from 'types/Contact';

export interface ExternalContact {
  externalContactId: string;

  name: string;
  companyName?: string;
  title?: string;
  linkedinUrl?: string;

  emails: ContactEmail[];
  phoneNumbers: ContactPhoneNumber[];
  tags?: string[];
  otherLinks?: string[];
}

export enum ExternalContactEventType {
  NOTE = 'NOTE',
  TASK = 'TASK',
}

export interface ExternalContactEvent {
  type: ExternalContactEventType;
  title: string;
  description: string;
}

export type ExternalContactUpdateValues = Partial<
  Omit<ExternalContact, 'externalContactId'>
>;

export enum ExternalContactTagAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}
