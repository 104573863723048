import { capitalize, debounce } from 'lodash';
import { DateTime } from 'luxon';
import { ContactType } from 'types/Contact';
import { IntegrationVendorName } from 'types/Integration';
import type { Integration } from 'types/Integration';

const ISO_DATE_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

export const convertISOToDates = (obj: any): void => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'object') {
      convertISOToDates(value);
    } else if (
      typeof value === 'string' &&
      ISO_DATE_REGEX.test(value) &&
      DateTime.fromISO(value).isValid
    ) {
      obj[key] = DateTime.fromISO(value).toJSDate();
    }
  }
};

export const debouncedInput = debounce((fn: () => void) => fn(), 250);

export const getReadableConstant = (constantName: string) =>
  capitalize(constantName.replace(/_/g, ' '));

export const getStringKeysFromEnum = (e: object): string[] =>
  Object.keys(e).filter((key) => isNaN(Number(key)));

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;

export const sanitizeMessageHtml = (message: string): string => {
  const regex = /<p><\/p>/g;
  return message.replace(regex, '<p>&nbsp;</p>');
};

export const wait = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const poll = async (
  fn: () => Promise<any>,
  fnCondition: (result: any) => boolean,
  ms: number
) => {
  let result = await fn();
  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }
  return result;
};

export const getRandomNumberBetween = ({
  min,
  max,
}: {
  min: number;
  max: number;
}) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getRelativeTime = ({
  date,
  zone = 'utc',
}: {
  date: Date;
  zone?: string;
}) => {
  const diffInSeconds = Math.abs(
    DateTime.fromJSDate(date, { zone }).diffNow('seconds').seconds
  );
  const diffUnderMinute = Math.abs(diffInSeconds) < 60;

  if (diffUnderMinute && diffInSeconds < 0) {
    return 'a few moments ago';
  }

  return DateTime.fromJSDate(date, { zone }).toRelative({
    unit: ['days', 'hours', 'minutes', 'seconds'],
  });
};

export const convertToHtml = (text: string): string => {
  if (!text) {
    return '<p></p>';
  }

  // already HTML
  if (text.trim().startsWith('<')) {
    return text;
  }

  const paragraphs = text.split('\n').map((line) => `<p>${line.trim()}</p>`);
  return !paragraphs.length ? '<p></p>' : paragraphs.join('');
};

export const convertToPlainText = (html: string): string => {
  if (!html) {
    return '';
  }

  // already plain text
  if (!html.trim().startsWith('<')) {
    return html;
  }

  const temp = document.createElement('div');
  temp.innerHTML = html;

  const paragraphs = temp.getElementsByTagName('p');

  if (!paragraphs.length) {
    return temp.textContent?.trim() || '';
  }

  return Array.from(paragraphs)
    .map((p) => p.textContent?.trim())
    .join('\n');
};

export const generateIntegrationLink = ({
  contactType,
  externalContactId,
  integration,
}: {
  contactType: ContactType;
  externalContactId: string;
  integration: Integration;
}): string | null => {
  switch (integration.vendor) {
    case IntegrationVendorName.CRELATE:
      return `https://app.crelate.com/go#stage/_Contacts/DefaultView/${externalContactId}`;
    case IntegrationVendorName.RECRUITCRM:
      if (contactType === ContactType.CANDIDATE) {
        return `https://app.recruitcrm.io/v1/candidate/${externalContactId}`;
      } else {
        return `https://app.recruitcrm.io/v1/contact/${externalContactId}`;
      }
    default:
      return null;
  }
};
