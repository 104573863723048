import * as Sentry from '@sentry/browser';
import {
  createUserWithEmailAndPassword as fbCreateUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification as fbSendEmailVerification,
  sendPasswordResetEmail as fbSendPasswordResetEmail,
  signInWithEmailAndPassword as fbSignInWithEmailAndPassword,
  signInWithPopup as fbSignInWithPopup,
  signOut as fbSignOut,
} from 'firebase/auth';
import { isNull } from 'lodash';
import { proxy, useSnapshot } from 'valtio';
import { firebaseAuth } from '@/utils/firebase';
import { isPersonalEmail } from 'helpers/emails';
import { getCurrentUser, getOrCreateUser } from 'src/client/api';

interface AuthStore {
  chromeExtensionId: string | null;
  isLoading: boolean;
  isSignedIn: boolean;
  isAdminUser: boolean;
  isSysUser: boolean;
  isBillingUser: boolean;
}

const EXTENSTION_ID_LIST =
  process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID?.split('|').filter((id) => id) ??
  [];

const handleInvalidExtensionId = (error: Error) => {
  if (error.message.includes('Invalid extension id')) {
    console.warn(`Error initializing Chrome extension: ${error}`);
    Sentry.captureMessage(`Error initializing Chrome extension: ${error}`);
  }
};

const sendAuthTokenAndStoreId = async ({
  extensionId,
  token,
}: {
  extensionId: string;
  token: string;
}) => {
  try {
    const response = await chrome.runtime?.sendMessage(extensionId, {
      type: 'JWT_TOKEN',
      token,
    });

    if (response.status === 'Received') {
      setChromeExtensionId(extensionId);
    }
  } catch (error) {
    handleInvalidExtensionId(error as Error);
  }
};

export const setChromeExtensionToken = async (token: string) => {
  for (const extensionId of EXTENSTION_ID_LIST) {
    sendAuthTokenAndStoreId({ extensionId, token });
  }
};

export const checkChromeExtension = async (): Promise<boolean> => {
  for (const extensionId of EXTENSTION_ID_LIST) {
    try {
      const response = await chrome.runtime?.sendMessage(extensionId, {
        type: 'CHECK_EXTENSION',
      });

      if (response.status === 'Received') {
        setChromeExtensionId(extensionId);
        return true;
      }
    } catch (error) {
      handleInvalidExtensionId(error as Error);
    }
  }

  return false;
};

export const setChromeExtensionId = (state: string) =>
  (store.chromeExtensionId = state);
export const setIsSignedIn = (state: boolean) => (store.isSignedIn = state);
export const setIsLoading = (state: boolean) => (store.isLoading = state);

export const setAuth = ({
  isAdminUser,
  isSysUser,
  isBillingUser,
}: {
  isAdminUser: boolean;
  isSysUser: boolean;
  isBillingUser: boolean;
}) => {
  store.isAdminUser = isAdminUser;
  store.isSysUser = isSysUser;
  store.isBillingUser = isBillingUser;
};

const getFormattedUser = async ({
  displayName,
}: {
  displayName?: string;
}): Promise<{ authId: string; name: string | null; token: string }> => {
  const firebaseUser = firebaseAuth.currentUser;

  if (!firebaseUser) {
    throw new Error('User not found');
  }

  const token = await firebaseUser.getIdToken();

  return {
    authId: firebaseUser.uid,
    name: displayName ?? firebaseUser.displayName,
    token,
  };
};

const handleSignIn = async ({ displayName }: { displayName?: string } = {}) => {
  const { authId, name, token } = await getFormattedUser({ displayName });

  const signInResult = await getOrCreateUser({
    authId,
    name: name ?? undefined,
  });

  if (isNull(signInResult)) {
    throw new Error('Your account is not active. Please contact support.');
  }

  if (!firebaseAuth.currentUser) {
    Sentry.captureMessage(
      'Firebase user not found after login - likely db corruption'
    );
  }

  if (signInResult.isEmailVerified) {
    setIsSignedIn(true);
    await setChromeExtensionToken(token);
  } else {
    console.error('User is not logged in or email is not verified');
    console.log('User:', signInResult);
  }
};

// triggers for both existing user sign and new user sign up
export const signInWithGoogle = async (): Promise<{
  success: boolean;
  error?: string;
}> => {
  store.isLoading = true;

  try {
    const provider = new GoogleAuthProvider();
    const result = await fbSignInWithPopup(firebaseAuth, provider);

    if (result.user.email && isPersonalEmail(result.user.email)) {
      const existingUser = await getCurrentUser();

      if (!existingUser) {
        await result.user.delete();
        await fbSignOut(firebaseAuth);

        return {
          success: false,
          error: 'Personal emails are not allowed',
        };
      }
    }

    await handleSignIn();
    return { success: true };
  } catch (error) {
    return { success: false, error: (error as Error).message };
  } finally {
    store.isLoading = false;
  }
};

export const signInWithEmailAndPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ success: boolean; error?: string }> => {
  store.isLoading = true;

  try {
    await fbSignInWithEmailAndPassword(firebaseAuth, email, password);

    await handleSignIn();
    return { success: true };
  } catch (error) {
    return { success: false, error: (error as Error).message };
  } finally {
    store.isLoading = false;
  }
};

export const createUserWithEmailAndPassword = async ({
  name,
  email,
  password,
}: {
  name: string;
  email: string;
  password: string;
}): Promise<{ success: boolean; error?: string }> => {
  store.isLoading = true;

  try {
    await fbCreateUserWithEmailAndPassword(firebaseAuth, email, password);
    const currentUser = firebaseAuth.currentUser;

    if (!currentUser) {
      return { success: false, error: 'User not found' };
    }

    await fbSendEmailVerification(currentUser, {
      url: `${process.env.NEXT_PUBLIC_API_BASE_URL}/campaigns`,
      handleCodeInApp: true,
    });

    await handleSignIn({ displayName: name });
    return { success: true };
  } catch (error) {
    return { success: false, error: (error as Error).message };
  } finally {
    store.isLoading = false;
  }
};

export const sendPasswordResetEmail = async ({
  email,
}: {
  email: string;
}): Promise<{ success: boolean; error?: string }> => {
  try {
    await fbSendPasswordResetEmail(firebaseAuth, email);
    return { success: true };
  } catch (error) {
    return { success: false, error: (error as Error).message };
  }
};

export const resendVerificationEmail = async (): Promise<void> => {
  const currentUser = firebaseAuth.currentUser;

  if (!currentUser) {
    return;
  }

  await fbSendEmailVerification(currentUser, {
    url: `${process.env.NEXT_PUBLIC_API_BASE_URL}/campaigns`,
    handleCodeInApp: true,
  });
};

export const handleSignOut = async (): Promise<void> => {
  // redirects to /login because of withAuthRedirect
  store.isSignedIn = false;
  Sentry.setUser(null);
};

export const signOut = async (): Promise<void> => {
  await fbSignOut(firebaseAuth);

  if (!!(window as any).Intercom) {
    (window as any).Intercom('shutdown');
  }

  await handleSignOut();
};

const store = proxy<AuthStore>({
  chromeExtensionId: null,
  isLoading: true,
  isSignedIn: false,
  isAdminUser: false,
  isSysUser: false,
  isBillingUser: false,
});

export const useAuthStore = () => useSnapshot(store);
