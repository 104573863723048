import type { CampaignStatus, StateData } from 'types/Campaign';
import { IntegrationType } from 'types/Integration';
import type { Paging } from 'types/Paging';

export enum ContactPhoneNumberType {
  WORK = 'WORK',
  MOBILE = 'MOBILE',
}

export interface ContactPhoneNumber {
  number: string;
  type?: ContactPhoneNumberType;
}

export enum ContactEmailType {
  WORK = 'WORK',
  PERSONAL = 'PERSONAL',
}

export interface ContactEmail {
  email: string;
  type?: ContactEmailType;
  bounced?: boolean;
}

export enum ContactType {
  CANDIDATE = 'CANDIDATE',
  PROSPECT = 'PROSPECT',
}

export const ContactToIntegrationTypeMapping: Record<
  ContactType,
  IntegrationType
> = {
  [ContactType.CANDIDATE]: IntegrationType.ATS,
  [ContactType.PROSPECT]: IntegrationType.CRM,
};

export interface ContactLocation {
  geoRegion?: string;
}

export interface WorkExperienceDetail {
  title?: string;
  description?: string;
  startYear: number;
  endYear: number;
  isCurrent: boolean;
  organization?: {
    name: string;
    website?: string;
  };
}

export interface ContactCompanyInfo {
  name: string;
  linkedinUrl?: string;
  website?: string;
  description?: string;
}

export interface AdditionalInfo {
  profileBio?: string;
  workHistory?: WorkExperienceDetail[];
  companyInfo?: ContactCompanyInfo;
}

export interface ContactExtraData {
  doNotContact?: boolean;
  linkedinRecruiterUrl?: string;
}

export interface ContactFilter {
  campaignStatusFilter?: CampaignStatus[];
  contactedWithinDays?: number;
  contactIds?: number[];
  hasEmail?: boolean;
  hasExternalContactId?: boolean;
  linkedinUrl?: string;
  linkedinRecruiterUrl?: string;
  type?: ContactType;
  userId?: number;
}

export interface Contact {
  contactId: number;
  customerId: number;
  firstName: string;
  lastName: string;
  type: ContactType;
  email: ContactEmail | null;
  phoneNumber: ContactPhoneNumber | null;
  addedByUserId: number;
  headline: string | null;
  companyName: string | null;
  title: string | null;
  industry: string | null;
  location: ContactLocation | null;
  additionalInfo?: AdditionalInfo;
  startDateMonth: number | null;
  startDateYear: number | null;
  linkedinUrn: string;
  linkedinUrl: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  externalContactId?: string;
  extraData?: ContactExtraData | null;
}

export interface GetContactListReturn {
  contacts: Contact[];
  paging: Paging;
}

export type NewContact = Omit<Contact, 'contactId' | 'customerId'>;

export type ImportContact = NewContact & Partial<StateData>;
