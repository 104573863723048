const PERSONAL_EMAIL_PROVIDERS = {
  aol: ['aol.com'],
  att: ['att.net'],
  bell: ['bell.net'],
  bellsouth: ['bellsouth.net'],
  comcast: ['comcast.net'],
  gmail: ['gmail.com', 'googlemail.com'],
  icloud: ['icloud.com'],
  mail: ['mail.com'],
  me: ['me.com'],
  microsoft: [
    'hotmail.com',
    'hotmail.co.uk',
    'hotmail.fr',
    'live.com',
    'msn.com',
    'outlook.com',
  ],
  protonmail: ['protonmail.com', 'proton.me'],
  rogers: ['rogers.com'],
  sympatico: ['sympatico.ca'],
  verizon: ['verizon.net'],
  yahoo: [
    'yahoo.ca',
    'yahoo.co.in',
    'yahoo.co.jp',
    'yahoo.co.uk',
    'yahoo.com.au',
    'yahoo.com.br',
    'yahoo.com',
    'yahoo.de',
    'yahoo.es',
    'yahoo.fr',
    'yahoo.it',
  ],
};

export const PERSONAL_EMAIL_DOMAINS = Object.values(
  PERSONAL_EMAIL_PROVIDERS
).flat();

export const isPersonalEmail = (email: string): boolean => {
  const domain = email.split('@')[1]?.toLowerCase();
  return PERSONAL_EMAIL_DOMAINS.includes(domain);
};
